import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"lines":"two"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Dark Mode")]),_c(VListItemSubtitle,[_vm._v(" Enable dark mode in the whole app ")])],1),_c(VListItemAction,[_c(VSwitch,{model:{value:(_vm.dark),callback:function ($$v) {_vm.dark=$$v},expression:"dark"}})],1)],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'privacy_policy' })}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Privacy Policy")])],1)],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'terms_and_conditions' })}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Terms of Use")])],1)],1),_c(VDivider)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }