<template>
    <div class="bg-type-2" style="border-top: solid 1px var(--grey); margin-top: 10px;">
        
        <div class="font-weight-bold pa-4 text-type-6">Indian Premier League</div>

        <table class="standings-table text-center mb-8" style="width: 100%;">
            <tr>
                <th width="12%">No</th>
                <th width="22%" class="text-left">Teams</th>
                <th width="8%">M</th>
                <th width="8%">W</th>
                <th width="8%">L</th>
                <th width="8%">T</th>
                <th width="8%">N/R</th>
                <th width="8%">PT</th>
                <th width="10%">NRR</th>
            </tr>
            <tr class="font-weight-bold" v-for="(standing, i) in standings" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                    <div class="d-flex">
                        <div>
                            <v-img class="mr-2 img-bg" height="30px" width="30px" :src="standing.image"></v-img>
                        </div>
                        
                        <div>{{ standing.team }}</div>
                    </div>
                </td>
                <td>{{ standing.m }}</td>
                <td>{{ standing.w }}</td>
                <td>{{ standing.l }}</td>
                <td>{{ standing.t }}</td>
                <td>{{ standing.nr }}</td>
                <td>{{ standing.pt }}</td>
                <td>{{ standing.nrr }}</td>
            </tr>
        </table>

    </div>
</template>
<script>

import team8 from "../../assets/team-8.png"
import team9 from "../../assets/team-9.png"
import team10 from "../../assets/team-10.png"
import team7 from "../../assets/team-7.png"
import team3 from "../../assets/team-3.png"
import team11 from "../../assets/team-11.png"
import team4 from "../../assets/team-4.png"
import team5 from "../../assets/team-5.png"
import team12 from "../../assets/team-12.png"
import team6 from "../../assets/team-6.png"


export default {
    name: 'Standings',
    data(){
        return {
            standings: [
                {
                    team: 'LSG',
                    image: team8,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'RR',
                    image: team9,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'KKR',
                    image: team10,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'GT',
                    image: team7,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'CSK',
                    image: team3,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'PBKS',
                    image: team11,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'RCB',
                    image: team4,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'MI',
                    image: team5,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'SRH',
                    image: team12,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                },
                {
                    team: 'DC',
                    image: team6,
                    m: '4',
                    w: '3',
                    l: '1',
                    t:' 0',
                    nr: '0',
                    pt: '6',
                    nrr: '1.50'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.standings-table{
    border-collapse: collapse;
    th {
        text-transform: uppercase;
        background-color: #E4EFEC;
        padding: 12px 0px;
        color: #787F7F;
    }
    td{
        padding: 12px 0px;
        border-bottom: 1px solid var(--grey);
    }
}
</style>