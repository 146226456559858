<template>
    <v-card outlined class="mt-3">

        <v-card-text>
                
            <v-row no-gutters class="font-weight-black">
                <v-col cols="6" class="grey-text">{{ data.title }}</v-col>
                <v-col cols="6" class="primary--text text-right">{{ data.team_score }} Runs, {{ data.team_wicket }} Wickets</v-col>
            </v-row>

            <div class="d-flex align-center mt-4">
                <div class="font-weight-black text-type-1" style="font-size: 16px;">{{ data.team_score }}/{{ data.team_wicket }}</div>
                <div class="flex-grow-1 d-flex justify-end" style="gap: 10px;">
                    
                    <div class="ball" v-for="(ball, i) in data.balls" :key="i" :style="getBallStyle(ball)">
                        {{ ball  }}
                    </div>

                </div>
            </div>

            <v-divider class="mt-3 mb-7"/>

            <v-row class="text-type-1" no-gutters>
                <v-col cols="6">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="d-flex align-center justify-end">
                                <CricketBat class="mr-1"/>
                                {{ data.batsman_1_name }}
                            </div>
                            <div class="font-weight-black text-right">{{ data.batsman_1_runs }}({{ data.batsman_1_balls }})</div>
                        </v-col>
                        <v-col cols="6">
                            <div class="d-flex align-center justify-end"><CricketBat class="mr-1"/>{{ data.batsman_2_name }}</div>
                            <div class="font-weight-black text-right">{{ data.batsman_2_runs }}({{ data.batsman_2_balls }})</div>
                        </v-col>
                    </v-row>        
                </v-col>
                <v-col cols="6" class="text-right">
                    <div class="d-flex align-center justify-end"><CricketBall class="mr-1"/>{{ data.bolwer_name }}</div>
                    <div class="font-weight-black">{{ data.batsman_2_runs }}({{ data.batsman_2_balls }})</div>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>
</template>
<script>
import CricketBall from "./../../../assets/svg/CricketBall.vue"
import CricketBat from "./../../../assets/svg/CricketBat.vue"

export default {
    components:{
        CricketBall, CricketBat
    },
    props: [
        'data'
    ],
    methods: {}
}

</script>