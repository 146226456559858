<template>
    <div>

        <div v-if="loading">
            <v-skeleton-loader
                v-for="(item, i) in 4" :key="i"
                class="mx-auto border mb-4"
                max-height="300"
                type="article">
            </v-skeleton-loader>
        </div>

        <EmptyState v-if="!loading && list != null && list.length == 0"/>

        <div v-if="data && data.length > 0">
            <v-lazy-list :items="data" @on-load-more="loadMore">
                <template #item="{value}">
                    <MatchCard class="mb-4"
                        :is_upcoming="true"
                        :data="value.item" />
                </template>
            </v-lazy-list>
        </div>

    </div>
</template>
<script>

import MatchCard from "./../../MatchCard.vue";
import EmptyState from "./../../EmptyState.vue";

export default {
    name: 'UpcomingTab',
    components: {
        MatchCard, EmptyState
    },
    data(){
        return{
            list: [], 
            loading: false,
            data: []
        }
    },
    methods: {
        fetchUpcomingMatches() {
            this.loading = true;
            api("upcomingMatches")
                .then(response => {
                    console.log("upcomingMatches", response)
                    this.loading = false;
                    if(response && response.status) {
                        this.list = response.data;
                    }else{
                        this.list = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.list = [];
                    console.log("error", error)
                })
        },
        loadMore() {
            if(this.data.length >= this.list.length) return;
            let x = this.list.slice(this.data.length, this.data.length + 5);
            x.forEach(y => {
                this.data.push(y);
            });
        }
    },
    mounted() {
        setTimeout(() => {
            this.fetchUpcomingMatches();
        }, 500);
    },
    watch: {
        list() {
            if(this.list && this.list.length > 0) {
                this.data = Object.assign([], this.list.slice(0, 5));
            }
        }
    }
}
</script>