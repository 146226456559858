import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{attrs:{"appBarExtensionHeight":_vm.bottomTab == 0 ? 34 : 0},scopedSlots:_vm._u([{key:"appbarExtension",fn:function(){return [(_vm.bottomTab == 0)?_c(VTabs,{staticClass:"main-tabs",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item,i){return _c(VTab,{key:i},[_vm._v(_vm._s(item))])}),1):_vm._e()]},proxy:true},{key:"bottomNavigation",fn:function(){return [_c(VBottomNavigation,{staticStyle:{"align-items":"center"},attrs:{"color":"primary","app":"","grow":""},model:{value:(_vm.bottomTab),callback:function ($$v) {_vm.bottomTab=$$v},expression:"bottomTab"}},_vm._l((_vm.bottomNavigation),function(item,i){return _c(VBtn,{key:i,attrs:{"ripple":false,"color":"transparent"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c(VIcon,[_vm._v(_vm._s(item.icon))])],1)}),1)]},proxy:true}])},[_c(VTabsItems,{attrs:{"value":_vm.bottomTab,"touchless":true}},[_c(VTabItem,[_c('HomeTab',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),_c(VTabItem,[_c('SeriesTab')],1),_c(VTabItem,[_c('MoreTab')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }