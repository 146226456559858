<template>
    <div class="py-6 px-3">

        <div v-if="loading" class="mt-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>

        <EmptyState v-if="!loading && data != null && data.length == 0">

        </EmptyState>

        <div v-for="(inning, i) in data" :key="i">
            <div v-for="(over, j) in inning" :key="j">
                <div v-for="(ball, k) in over" :key="k">
                    <CommentaryRow1 v-if="ball.type == 1" :data="ball.data"></CommentaryRow1>
                    <CommentaryRow2 v-else-if="ball.type == 2" :data="ball.data"></CommentaryRow2>
                </div>
            </div>
        </div>


    </div>
</template>
<script>

import CommentaryRow1 from "./Commentary/CommentaryRow1.vue"
import CommentaryRow2 from "./Commentary/CommentaryRow2.vue"

import EmptyState from "./../EmptyState.vue";

export default {
    components: {
    CommentaryRow1,
    CommentaryRow2,
    EmptyState,
    EmptyState
},
    data() {
        return {
            data: null,
            timeout: null,
            loading: false,
        }
    },
    methods: {
        fetchCommentary() {
            // this.loading = true;
            api("commentary/" + this.$route.params.match_id)
                .then(response => {
                    console.log("commentary", response)
                    this.loading = false;
                    if(response && response.status) {
                        this.data = this.parseCommentaryData(response.data);
                    }else{
                        this.data = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.data = [];
                    console.log("error", error)
                })

        },
        parseCommentaryData(data) {

            for (let inning in data) {

                for(let over in data[inning]) {

                    let overLength = data[inning][over].length;

                    for(let i = 0; i < data[inning][over].length; i++) {
                        let ball = data[inning][over][i];
                        if(ball.type == 2) {
                            let balls = [];
                            for(let k = ( i + 1 ); k < overLength; k++) {
                                let nextBall = data[inning][over][k];
                                if(nextBall.data.wicket == "1"){
                                    balls.push("W");
                                }else{
                                    balls.push(nextBall.data.runs);
                                }
                                
                            }
                            data[inning][over][i]["data"]["balls"] = balls;
                        }

                    }
                }
            }

            return data;
        }
    },
    mounted(){
        this.timeout = setInterval(() => {
            this.fetchCommentary();
        }, 500);
    },
    beforeDestroy() {
        if(this.timeout) {
            clearInterval(this.timeout);
        }
    }
}
</script>
<style lang="scss">
.ball {
    box-shadow: 0.1px 0.1px 2px 0.1px #6e7480;
    background-color: var(--grey-2);
    height: 26px;
    width: 26px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
</style>
