<template>
    <div class="bg-type-2" style="border-top: solid 1px var(--grey); margin-top: 10px; height: 100%;">

        <iframe style="height: 100%; width: 100%;" src="https://syndication.twitter.com/srv/timeline-list/screen-name/ICC/slug/cricketers-on-twitter?dnt=false&embedId=twitter-widget-0&features=e30%3D&frame=false&hideBorder=false&hideFooter=false&hideHeader=false&hideScrollBar=false&lang=en&origin=file%3A%2F%2F%2FUsers%2Faniket%2FProjects%2Ftemp.html&showHeader=true&transparent=false&widgetsVersion=aaf4084522e3a%3A1674595607486"></iframe>

        <!-- <EmptyState/> -->

        <!-- <img width="100%" :src="news">

        <div class="pa-3" style="border-bottom: 1px solid var(--grey)">
            <div class="font-weight-bold mb-3 text-type-6">Rohit Sharma's Second half century in 25 Innings For Mumbai Indians</div>
            <div class="d-flex align-center" style="color: #787F7F;">
                <div class="d-flex align-center mr-3">
                    <Calendar class="mr-1"/> 12 Apr 2023
                </div>
                <div class="d-flex align-center">
                    <Clock class="mr-1"/> 2 min
                </div>
            </div>
        </div>

        <div v-for="(news, i) in subnews" :key="i" class="pa-4 d-flex" style="border-bottom: 1px solid var(--grey)">
            <div style="width: 35%;">
                <img width="100%" :src="news.image">
            </div>
            <div class="pl-6" style="width: 65%;">
                <div class="font-weight-bold mb-3 text-type-6">
                    {{ news.title }}
                </div>
                <div class="d-flex align-center" style="color: #787F7F;">
                    <div class="d-flex align-center mr-3">
                        <Calendar class="mr-1"/> 12 Apr 2023
                    </div>
                    <div class="d-flex align-center">
                        <Clock class="mr-1"/> 2 min
                    </div>
                </div>
            </div>
        </div>

        <div class="my-10"/> -->



    </div>
</template>
<script>

import news from "../../assets/news.png"
import news2 from "../../assets/news-2.png"
import news3 from "../../assets/news-3.png"

import Calendar from "./../../assets/svg/Calendar.vue"
import Clock from "./../../assets/svg/Clock.vue"

import EmptyState from "./../EmptyState.vue";

export default {
    components: {
        Calendar, Clock,
        EmptyState
    },
    data(){
        return {
            news, news2, news3,
            subnews: [
                {
                    title: 'IPL 2023: Delhi Capitals Vs Mumbai Indians Review By Harbhajan Singh',
                    image: news2
                },
                {
                    title: 'IPL 2023: Chennai Super Kings Vs Rajasthan Royals Preview By Aakash Chopra',
                    image: news3
                },
            ]
        }
    }
}
</script>