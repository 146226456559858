<template>
    <div>

        <div v-if="loading" class="py-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>

        <EmptyState v-if="!loading && data!= null && data.length == 0"/>

        <div v-else>
            <div class="bg-type-2" style="border-top: solid 1px var(--grey); margin-top: 10px;">
                <div v-if="data && data.scorecard"> 
                    <v-expansion-panels class="scorecard-ep" :value="0" flat>
                        <v-expansion-panel v-for="(team_data, k) in data.scorecard" :key="k">
                            <v-expansion-panel-header>
                                <div class="font-weight-bold d-flex" style="padding: 14px 14px;">
                                    <div class="flex-grow-1 d-flex align-center">
                                        <span class="text-type-1">{{ team_data.team.short_name }} Innings</span>
                                        <!-- <span style="color: var(--grey)">(1st Inn)</span> -->
                                    </div>
                                    <div class="flex-grow-1 d-flex align-center">
                                        <div class="flex-grow-1 text-center">
                                            <span style="color: var(--grey)">({{ team_data.team.over }} overs)</span> <span>{{ team_data.team.score }}/{{ team_data.team.wicket }}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <template v-slot:actions>
                                    <ArrowUp class="primary--text"/>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                
                                <table class="scorecard-table">
                                    <tr class="">
                                        <th width="45%">BATSMAN</th>
                                        <th width="10%">R</th>
                                        <th width="10%">B</th>
                                        <th width="10%">4s</th>
                                        <th width="10%">6s</th>
                                        <th width="10%">SR</th>
                                    </tr>
                                    <tr v-for="(player, k) in team_data.batsman" :key="k">
                                        <td>
                                            <div class="font-weight-black">{{ player.name }}</div>
                                            <div style="color: var(--grey-4);font-size: 14px;">{{ player.out_by }}</div>
                                        </td>
                                        <td>{{ player.run }}</td>
                                        <td>{{ player.ball }}</td>
                                        <td>{{ player.fours }}</td>
                                        <td>{{ player.sixes }}</td>
                                        <td>{{ player.strike_rate }}</td>
                                    </tr>
                                </table>

                                <table class="scorecard-table">
                                    <tr class="">
                                        <th width="45%">BOLWER</th>
                                        <th width="10%">O</th>
                                        <th width="10%">R</th>
                                        <th width="10%">M</th>
                                        <th width="10%">W</th>
                                        <th width="10%">ECO</th>
                                    </tr>
                                    <tr v-for="(player, k) in team_data.bolwer" :key="k">
                                        <td>
                                            <div class="font-weight-black">{{ player.name }}</div>
                                        </td>
                                        <td>{{ player.over }}</td>
                                        <td>{{ player.run }}</td>
                                        <td>{{ player.maiden }}</td>
                                        <td>{{ player.wicket }}</td>
                                        <td>{{ player.economy }}</td>
                                    </tr>
                                </table>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import ArrowUp from "../../assets/svg/ArrowUp.vue"
import EmptyState from "./../EmptyState.vue";

export default {
    components: {
        ArrowUp, EmptyState
    },
    data() {
        return {
            match_id: this.$route.params.match_id,
            data: null,
            loading: false,
        }
    },
    methods:{
        fetchScorecard() {

            this.loading = true;

            api("scorecard/" + this.match_id)
                .then(response => {
                    this.loading = false;
                    console.log("scorecard", response)
                    if(response && response.status) {
                        this.data = response.data;
                    } else {
                        this.data = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.data = [];
                    console.log("error", error)
                })
        }
    },
    mounted(){
        this.fetchScorecard();
    }
}
</script>
<style lang="scss">
.scorecard-table {

    border-collapse: collapse;
    
    th:first-child, 
    td:first-child{
        text-align: left;
        padding-left: 14px;
    }

    th:last-child,
    td:last-child {
        padding-right: 14px;
    }
    
    th:not(:first-child), 
    td:not(:first-child) {
        text-align: center;
    }

    td{
        padding: 10px 0;
    }

    th {
        color: var(--grey-3);
        padding-bottom: 6px;
        padding-top: 6px;
        font-weight: 500;
    }

    td, th {
        border-bottom: 1px solid var(--grey-2);
    }

}

.scorecard-ep{
    &.theme--dark.v-expansion-panels .v-expansion-panel{
        background-color: transparent !important;
    }
    .v-expansion-panel-header, .v-expansion-panel-content__wrap{
        padding: 0;
    }
    .v-expansion-panel-header--active{
        .v-expansion-panel-header__icon svg{
            transform: rotate(180deg);
        }
    }
}

</style>