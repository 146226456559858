<template>

    <div>

        <EmptyState v-if="!loading && data == null"></EmptyState>

        <div v-if="loading" class="py-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>
    
        <div v-if="!loading && data != null" class="bg-type-2">

            <div class="font-weight-bold pa-4 text-type-6">{{ name }}</div>

            <table class="standings-table text-center mb-8" style="width: 100%;">
                <tr>
                    <th width="12%">No</th>
                    <th width="22%" class="text-left">Teams</th>
                    <th width="8%">P</th>
                    <th width="8%">W</th>
                    <th width="8%">L</th>
                    <!-- <th width="8%">T</th> -->
                    <th width="8%">N/R</th>
                    <th width="8%">Pts</th>
                    <th width="10%">NRR</th>
                </tr>
                <tr class="font-weight-bold" v-for="(standing, i) in data" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <div class="d-flex text-left">
                            <!-- <img class="mr-2" height="30px" width="30px" :src="standing.image"> -->
                            <div>{{ standing.teams }}</div>
                        </div>
                    </td>
                    <td>{{ standing.P }}</td>
                    <td>{{ standing.W }}</td>
                    <td>{{ standing.L }}</td>
                    <!-- <td>{{ standing.t }}</td> -->
                    <td>{{ standing.NR }}</td>
                    <td>{{ standing.Pts }}</td>
                    <td>{{ standing.NRR }}</td>
                </tr>
            </table>

        </div>
    </div>
</template>
<script>

import EmptyState from "./../../components/EmptyState.vue";

export default {
    name: 'StandingsTab',
    components: {
        EmptyState
    },
    data() {
        return {
            data: null,
            name: this.$route.query.name ?? "Series",
            loading: false,
        }
    },
    methods:{
        fetchStandings() {
            this.loading = true;
            api("pointsTable/" + this.$route.params.series_id)
                .then(response => {
                    console.log("pointsTable", response)
                    this.loading = false;
                    if(response && response.status) {
                        this.data = response.data;
                    }
                }).catch(error => {
                    this.loading = false;
                    console.log("error", error)
                })
        }
    },
    mounted(){
        this.fetchStandings();
    }
}
</script>
<style lang="scss" scoped>
.standings-table{
    border-collapse: collapse;
    th {
        text-transform: uppercase;
        background-color: #E4EFEC;
        padding: 12px 0px;
        color: #787F7F;
    }
    td{
        padding: 12px 0px;
        border-bottom: 1px solid var(--grey);
    }
}
</style>