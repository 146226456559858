<template>
    <MainLayout :title="name" :showBackButton="true" :appBarExtensionHeight="34">
        
        <template v-slot:appbarExtension>
            <v-tabs class="main-tabs" v-model="tab">
                <v-tab v-for="(item, i) in tabs" :key="i">{{ item }}</v-tab>
            </v-tabs>
        </template>

        <div class="content">
            <v-tabs-items v-model="tab">
                <v-tab-item>
                
                    <MatchesTab/>
                    
                </v-tab-item>
                <!-- <v-tab-item>
                    <iframe style="height: 100%; width: 100%;" src="https://syndication.twitter.com/srv/timeline-list/screen-name/ICC/slug/cricketers-on-twitter?dnt=false&embedId=twitter-widget-0&features=e30%3D&frame=false&hideBorder=false&hideFooter=false&hideHeader=false&hideScrollBar=false&lang=en&origin=file%3A%2F%2F%2FUsers%2Faniket%2FProjects%2Ftemp.html&showHeader=true&transparent=false&widgetsVersion=aaf4084522e3a%3A1674595607486"></iframe>
                </v-tab-item> -->
                <v-tab-item>
                    <StandingsTab/>
                </v-tab-item>
            </v-tabs-items>
        </div>

        
        
    </MainLayout>
</template>
<script>

import MainLayout from "../../layouts/MainLayout.vue";
import MatchesTab from "../../components/SeriesView/MatchesTab.vue";
import StandingsTab from "../../components/SeriesView/StandingsTab.vue";
import EmptyState from "./../../components/EmptyState.vue";

export default{
    name: 'SeriesView',
    components: {
        MainLayout,
        MatchesTab, StandingsTab,
        EmptyState
    },
    data(){
        return {
            // tab: 0,
            tabs: [
                'Matches', 
                // 'News',
                'Standings'
            ],
            name: this.$route.query.name ?? "Series"
        }
    },
    computed: {
      tab:{
        get() {
          return this.$route.query?.tab ? parseInt(this.$route.query.tab) : 0;
        },
        set(newValue) {
          this.$router.replace({
            query:{
              tab: newValue
            }
          })
        }
      }
    }
}
</script>