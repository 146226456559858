<template>
    <div>

        <div v-if="loading">
            <v-skeleton-loader
                v-for="(item, i) in 4" :key="i"
                class="mx-auto border mb-4"
                max-height="300"
                type="article">
            </v-skeleton-loader>
        </div>

        <EmptyState v-if="!loading && data != null && data.length == 0"/>

        <div v-if="list && list.length > 0">
            <v-lazy-list :items="list" @on-load-more="loadMore">
                <template #item="{value}">
                    <MatchCard class="mb-4"
                        :is_recent="true"
                        :data="value.item" />
                </template>
            </v-lazy-list>
            <!-- <RecycleScroller
                :items="data"
                :item-size="230"
                key-field="match_id"
                v-slot="{ item }"
                :prerender="10">
                <MatchCard class="mb-4"
                    :is_recent="true"
                    :data="item"/>
            </RecycleScroller> -->
        </div>

        <!-- <MatchCard class="mb-4"
            :is_recent="true"
            :data="item"/> -->

        

    </div>
</template>
<script>
import MatchCard from "./../../MatchCard.vue";

import EmptyState from "./../../EmptyState.vue";

export default {
    name: 'ResultTab',
    components: {
        MatchCard, EmptyState
    },
    data(){
        return {
            data: null,
            list: [],
            loading: false,
        }
    },
    methods: {
        loadMore() {
            if(this.list.length >= this.data.length) return;
            let x = this.data.slice(this.list.length, this.list.length + 5);
            x.forEach(y => {
                this.list.push(y);
            });
        },
        fetchRecentMatches() {

            this.loading = true;

            api("recentMatches")
                .then(response => {
                    console.log("recentMatches", response)
                    this.loading = false;
                    if(response && response.status) {
                        this.data = response.data;
                    }else{
                        this.data = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.data = [];
                    console.log("error", error)
                })
        }
    },
    mounted() {
        // this.fetchRecentMatches();
        setTimeout(() => {
            this.fetchRecentMatches();
        }, 500);
    },
    watch: {
        data() {
            if(this.data && this.data.length > 0) {
                this.list = Object.assign([], this.data.slice(0, 5));
            }
        }
    }
}
</script>