import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      dark: (localStorage.getItem('dark') == 'true' || localStorage.getItem('dark') == null),
      themes: {
        light: {
          primary: '#ffffff'
        },
        dark: {
          primary: '#ffffff'
        }
      },
    },
  });
