<template>

    <v-card outlined @click="openMatchInfo">

        <v-card-text>

            <div style="color: #9FACAD;">
                <span v-if="is_upcoming" class="font-weight-bold text-type-4 text-uppercase">Upcoming</span>
                <div v-else-if="is_live" class="d-inline-block">
                    <span class="live-beacon"></span>
                    <span class="ml-2 text-uppercase" style="color:#e93f33; font-weight: bold;">Live</span>
                </div>
                <span v-else class="font-weight-bold text-type-3 text-uppercase">Result</span> - {{ text_a }}
            </div>

            <table class="font-weight-bold mt-2" style="width: 100%">
                <tr :class="[ is_recent ? getTextColor(1) : 'text-type-1' ]">
                    <td class="d-flex align-center">
                        <div class="mr-2 pa-1">
                            <v-img
                                v-if="data.team_a_img"
                                class="img-bg rounded-circle"
                                width="24"
                                aspect-ratio="1"
                                :src="data.team_a_img"/>
                        </div>
                        {{ data.team_a }}
                    </td>
                    <td v-if="!is_upcoming" class="text-right">
                        {{ team_a_score }}
                    </td>
                </tr>
                <tr :class="[ is_recent ? getTextColor(2) : 'text-type-1' ]">
                    <td class="d-flex align-center">
                        <div class="mr-2 pa-1">
                            <v-img
                                v-if="data.team_b_img"
                                class="img-bg rounded-circle"
                                width="24"
                                aspect-ratio="1"
                                :src="data.team_b_img"/>
                        </div>
                        {{ data.team_b }}
                    </td>
                    <td v-if="!is_upcoming" class="text-right">
                        {{ team_b_score }}
                    </td>
                </tr>
            </table>

            <div class="mt-2 mb-4 text-type-5">
                {{ computed_text_2 }}
            </div>

            <div class="d-flex px-6" style="width: 100%;">
                <v-btn 
                    class="font-weight-bold mr-10 btn-1" 
                    style="flex:1;" 
                    color="primary" 
                    text rounded
                    :ripple="false"
                    @click="openFixtures">
                    Fixtures
                </v-btn>
                <v-btn 
                    class="font-weight-bold btn-1"
                    style="flex:1;"
                    color="primary"
                    text rounded
                    :ripple="false"
                    @click="openStandings">
                    Standings
                </v-btn>
            </div>

        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'MatchCard',
    props: {
        is_recent: {
            type: Boolean
        },
        is_upcoming: {
            type: Boolean
        },
        is_live: {
            type: Boolean
        },
        data:{
            type: Object
        }
    },
    computed: {
        text_a() {
            let a = this.data.venue.split(",");
            let venue = "";
            if(a.length > 1) {
                venue = a[1].trim();
            }else{
                venue = a[0];
            }
            return this.data.matchs + " - " + venue;
        },
        computed_text_2() {
            if(this.is_upcoming) {
                return "Match starts on " + this.data.match_date.replace("-", " ") + " 2023 at " + this.data.match_time;
            }
            if(this.is_recent){
                return this.data?.result;
            }
        },
        team_a_score() {
            return (
                this.data.team_a_scores != undefined && this.data.team_a_scores != "" && 
                this.data.team_a_over != undefined && this.data.team_a_over != ""
            ) ? `${ this.data.team_a_scores } (${ this.data.team_a_over })` : ''
        },
        team_b_score() {
            return (
                this.data.team_b_scores != undefined && this.data.team_b_scores != "" && 
                this.data.team_b_over != undefined && this.data.team_b_over != ""
            ) ? `${ this.data.team_b_scores } (${ this.data.team_b_over })` : ''
        }
    },
    methods: {
        openMatchInfo() {

            

            let match_status = "";

            if(this.is_upcoming) match_status = "upcoming";
            if(this.is_live) match_status = "live";
            if(this.is_recent) match_status = "recent";

            console.log("openMatchInfo", match_status)

            this.$router.push({ name: 'match-info',
                params: {
                    match_id: this.data.match_id, 
                },
                query: {
                    match_status: match_status
                }
            });
        },
        getTextColor(team) {
            if(this.data.result.includes( team == 1 ? this.data.team_a : this.data.team_b )) {
                return "text-type-1";
            }else{
                return "text-type-2";
            }
        },
        openStandings() {
            this.$router.push({
                name: 'series-view',
                params:{
                    series_id: this.data.series_id
                },
                query:{
                    tab: 2
                }
            })
        },
        openFixtures(){
            this.$router.push({
                name: 'series-view',
                params:{
                    series_id: this.data.series_id
                },
                query:{
                    tab: 0
                }
            })
        }
    }
}
</script>
<style>
.live-pill {
    color: white;
    background-color: #DC3545;
    font-weight: bold;
    padding: 2px 12px 3px 12px;
    border-radius: 16px;
}
</style>