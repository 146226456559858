import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import VueAxios from 'vue-axios'
import Dayjs from 'vue-dayjs';
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VLazyList from 'v-lazy-list';

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(Dayjs);

Vue.use(VueVirtualScroller)

Vue.use(VLazyList)

Vue.mixin({
  methods: {
    getBallStyle(d) {
      switch(d) {
          case "6":
          case "4":
              return { backgroundColor: 'var(--green)', color: 'white' };
          case 'W':
          case 'w':
              return { backgroundColor: 'var(--danger-red)', color: 'white' };
          default:
              return { color: 'black' }
      }
    },
    getShortName(name) {
      let arr = name.split(" ");
      arr.forEach((o, i) => {
        if(i < (arr.length -1) ) {
          arr[i] = arr[i][0];
        }
      });
      let p = arr.join(" ");
      return p.replace("*", "");
    }
  }
});

window.api = async function(url) {
  let response = await fetch(`https://apidata.live/api/score/cricket/${url}`);
  return response.json();
}

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
