<template>

    <div>

        <EmptyState v-if="!loading && data != null && data.length == 0"></EmptyState>

        <div v-if="loading" class="py-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>

        <div v-if="data && Object.keys(data).length > 0" class="bg-type-2" style="border-top: solid 1px var(--grey); margin-top: 10px;height: 100%;">

            <div>
                <div v-for="(squad, i) in data" :key="i" @click="showSquad(i)">
                    <div class="pa-4 d-flex align-center">
                        <div>
                            <v-img class="mr-5 rounded-circle img-bg"
                            width="40px" height="40px" 
                            :src="squad.flag"></v-img>
                        </div>
                        
                        <div class="text-uppercase font-weight-black flex-grow-1">
                            {{ squad.name }}
                        </div>
                        <ArrowUp style="transform: rotate(90deg);" class="primary--text"/>
                    </div>
                    <v-divider/>
                </div>
            </div>

        </div>

    </div>

    
</template>
<script>

import team5 from "./../../assets/team-5.png";
import team6 from "./../../assets/team-6.png";
import ArrowUp from "../../assets/svg/ArrowUp.vue";

import EmptyState from "./../EmptyState.vue";

export default { 
    name: 'Squads',
    components:{
        ArrowUp, EmptyState
    },
    data() {
        return {
            match_id: this.$route.params.match_id,
            data: null,
            squads: [
                {
                    name: 'Mumbai Indians',
                    image: team5
                },
                {
                    name: 'Delhi Capitals',
                    image: team6
                }
            ],
            loading: false,
        }
    },
    methods: {
        fetchSquadList() {
            this.loading = true;
            api("squadList/" + this.match_id)
                .then(response => {
                    console.log("squad", response)
                    this.loading = false;
                    if(response) {
                        this.data = response.data;
                    }else{
                        this.data = [];
                    }
                }).catch(error => {
                    this.loading = false;
                    this.data = [];
                    console.log("error", error)
                })
        },
        showSquad(i) {

            let squad = {
                match_id: this.match_id,
                squad: this.data[i]
            };
            
            localStorage.setItem('squad', JSON.stringify(squad));

            this.$router.push({
                name: 'squad',
                params: {
                    match_id: this.match_id
                }
            })
        }
    },
    mounted() {
        this.fetchSquadList();
    }
}
</script>