<template>

    <div>

        <v-row no-gutters class="mt-4">
            <v-col cols="2" class="d-flex justify-center align-center flex-column">
                <div class="font-weight-black mb-1">{{ data.overs }}</div>
                <div v-if="data.wicket == '1'" class="badge font-weight-bold white--text" style="background-color: var(--danger-red);">
                    W
                </div>
                <div v-else class="badge font-weight-bold" :style="getBallStyle(data.runs)">
                    {{ data.runs }}
                </div>
            </v-col>
            <v-col cols="10" style="font-size: 14px;line-height: 1.4;">
                <div>
                    {{ data.title }}, {{ data.description }}
                </div>
            </v-col>
        </v-row>

        <v-divider class="mt-4"/>

    </div>

</template>
<script>
export default {
    props: [
        'data'
    ],
    methods: {}
}
</script>
<style scoped>
.badge{
    background-color: var(--grey-2);
    min-width: 33px;
    border-radius: 6px;
    text-align: center;
    color: black;
    font-size: 14px;
    padding: 2px 0px;
}
</style>