<template>
    <div class="pt-6 px-3">

        <div v-if="loading" class="py-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>

        <EmptyState v-if="!loading && (matches == null || matches.length == 0)"></EmptyState>

        <div v-for="(day, i) in matches" :key="i" class="mb-8">
            <div class="font-weight-bold mb-3 ml-2">{{ i }}</div>
            <MatchCard class="mb-4"
                v-for="(match, j) in day" :key="j"
                :is_upcoming="true"
                :data="match"/>
        </div>
        
    </div>
</template>
<script>
import MatchCard from "../MatchCard.vue";
import EmptyState from "./../EmptyState.vue";

export default {
    name: 'MatchesTab',
    components: {
        MatchCard, EmptyState
    },
    data(){
        return {
            matches: [],
            series_id: this.$route.params.series_id,
            loading: false,
        }
    },
    
    methods: {
        parseMatches(matches) {
            let data = [];
            matches.forEach((match, i) => {
                if(!data[match.date_wise]) {
                    data[match.date_wise] = [];
                }
                data[match.date_wise].push(match)
            });
            return data;
        },
        getMatches() {

            this.loading = true;

            api(`matchesBySeriesId/${this.series_id}`)
                .then(response => {
                    console.log("matchesBySeriesId", response.data)
                    this.loading = false;
                    if(response && response.status) {
                        this.matches = Object.assign({}, this.parseMatches(response.data));
                    }
                }).catch(error => {
                    this.loading = false;
                    console.log("error", error)
                })
        }
    },
    mounted() {
        this.getMatches();
    }
}
</script>