<template>
    <MainLayout :title="title" :showBackButton="true">

        <EmptyState v-if="Object.keys(squad).length == 0 || squad.length == 0"/>

        <div class="content pt-4">
            <div class="squad-view" v-for="(type, i) in squad" :key="i">
                <div class="header px-6 py-2">
                    {{ i.replace("_", " ").toUpperCase() }}
                </div>
                <div v-for="(player, j) in type" :key="j">
                    <div class="px-6 py-4 d-flex align-center">
                        <div>
                            <v-img class="img-bg rounded-circle mr-4" height="40px" width="40px" aspect-ratio="1" :src="player.image"></v-img>
                        </div>
                        <div>{{ player.name }}</div>
                    </div>
                    <v-divider v-if="j < (type.length - 1)"/>
                </div>    
            </div>
        </div>

        

    </MainLayout>
</template>
<script>

import MainLayout from "./../../layouts/MainLayout";
import EmptyState from "./../../components/EmptyState.vue"

export default {
    data(){
        return {
            match_id: this.$route.params.match_id,
        }
    },
    components: {
        MainLayout,
        EmptyState
    },
    methods: {
        
    },
    mounted() {
        
    },
    computed: {
        data() {
            let c = localStorage.getItem('squad');
            if(c) {
                let arr = JSON.parse(c);
                console.log("arr", arr);
                if(arr.match_id == this.match_id) {
                    return arr;
                }
            }
            return [];
        },
        title() {
            return this.data?.squad?.name ?? 'Squad';
        },
        squad() {
            if(this.data && this.data.squad && this.data.squad.player) {

                let squad = {
                    batter: [],
                    all_rounder: [],
                    bowler: [],
                    wk: []
                };

                this.data.squad.player.forEach((player, k) => {
                    switch (player.play_role) {
                        case "WK-Batsman":
                        case "WK-Bowler":
                            squad.wk.push(player);
                            break;
                        case "Batsman":
                            squad.batter.push(player);
                            break;
                        case "Batting Allrounder":
                        case "Bowling Allrounder":
                            squad.all_rounder.push(player);
                            break;
                        case "Bowler":
                            squad.bowler.push(player);
                            break;
                    }
                })

                return squad;
            }
            return [];
        }
    }
}
</script>
<style lang="scss" scoped>
.squad-view {
    .header {
        background-color: #E4EFEC;
        color: #787F7F;
        font-size: 14px;
        font-weight: bold;
    }
    .v-divider{
        opacity: .4;
    }
}
.v-application.theme--dark{
    .squad-view {
        .header {
            background-color: #123A30;
            color: #B7C4C5;
        }
        .v-divider{
            opacity: 1;
        }
        
    }   
}
</style>