<template>
    <div class="py-6 px-5">

        <div v-if="loading" class="mt-12" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <v-progress-circular color="primary" size="60" indeterminate></v-progress-circular>
        </div>

        <EmptyState v-if="!loading && data != null && data.length == 0"/>

        <div v-else>
            <v-card v-if="toss" class="mb-6" outlined>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <div class="d-flex align-center">
                                <span class="flex-grow-1 grey-text font-weight-bold">TOSS</span><Coin height="18px" width="18px"/>
                            </div>
                        </v-col>
                        <v-col class="text-type-5" cols="9">
                            <span v-if="toss">{{ toss }}</span>
                            <span v-else>Awaiting Toss Message</span>
                            <!-- MI won the toss & elected to bowl -->
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="data" outlined>
                <v-card-text>
                    <div class="text-type-1 mb-4">{{ data.matchs }}, {{ data.team_a }} vs {{ data.team_b }}</div>
                    <v-row class="mb-2" no-gutters>
                        <v-col cols="3">
                            <div class="grey-text font-weight-bold">
                                SERIES
                            </div>
                        </v-col>
                        <v-col cols="9" class="text-type-1">
                            {{ data.series }}
                        </v-col>
                    </v-row>
                    <v-row class="mb-2" no-gutters>
                        <v-col cols="3">
                            <div class="grey-text font-weight-bold">
                                DATE
                            </div>
                        </v-col>
                        <v-col cols="9" class="text-type-1">
                            {{ data.match_date.replace("-", " ") }}, {{ data.match_time }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <div class="grey-text font-weight-bold">
                                VENUE
                            </div>
                        </v-col>
                        <v-col cols="9" class="text-type-1">
                            {{ data.venue }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>


            <v-card v-if="data" class="mt-6" outlined>
                <v-card-text>
                    
                    <v-row class="mb-2" no-gutters>
                        <v-col cols="3">
                            <div class="grey-text font-weight-bold">
                                UMPIRES
                            </div>
                        </v-col>
                        <v-col cols="9" class="text-type-1">
                            {{ data.umpire ? data.umpire : '-' }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <div class="grey-text font-weight-bold">
                                REFREE
                            </div>
                        </v-col>
                        <v-col cols="9" class="text-type-1">
                            {{ data.referee ? data.referee : '-' }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </div>

        

    </div>
</template>
<script>

import Coin from "../../assets/svg/Coin";
import EmptyState from "./../EmptyState.vue";

export default {
    components: {
        Coin, EmptyState
    },
    // props:[
    //     'toss', 'data'
    // ],
    props: {
        toss: {
            default: String
        },
        data: {},
        loading: false
    },
    data() {
        return {
            // data: null
        }
    },
    methods:{
        
    },
    mounted(){
        // this.fetchMatchInfo();
    }
}
</script>