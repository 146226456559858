<template>
    <v-app>
      <v-app-bar
        class="main-app-bar"
        app
        dark elevation="0"
        :extension-height="appBarExtensionHeight">

        <div class="d-flex" v-bind:style="[ this.showBackButton ? {} : { opacity: 0 } ]">
          <v-btn :ripple="false" icon @click="$router.back()" height="25px" width="25px">
            <ArrowBack />
          </v-btn>
        </div>

        <div class="d-flex justify-center" style="width: 100%;">
          <div class="flex-grow-1 justify-center d-flex align-center"> 
            <div v-if="title" class="font-weight-black">
              {{ title }}
            </div>
            <img v-else height="65" :src="exch" possition: align-center>
            <a :href="'//' + url" :target="_blank"  target="_blank"  type="button" class="font-weight-bold mr-10 btn-1 v-btn v-btn--rounded v-btn--text theme--dark v-size--small primary--text" > Sign Up </a>
          </div>
          <!-- <v-btn class="theme-toggle" :ripple="false" icon @click="changeMode" height="24px" width="24px" style="opacity: 0;">
            <DarkTheme/>
          </v-btn> -->
          
        </div>

        <template v-slot:extension>
            <slot name="appbarExtension"></slot>
        </template>
      </v-app-bar>
      <v-main>
        <div class="gradient-background"></div>
        <slot />
      </v-main>
      <slot name="bottomNavigation"/>
    </v-app>
  </template>
  
<script>

  import exch from "./../assets/11exchlogo.png"
  import ArrowBack from "../assets/svg/ArrowBack.vue"

  import DarkTheme from "../assets/svg/DarkTheme.vue"

  export default {
    name: 'App',
    components:{
      ArrowBack, DarkTheme
    },
    props: {
      appBarExtensionHeight: {
        type: Number,
        default: 0
      },
      title: {
        type: String,
        default: null
      },
      showBackButton: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      exch,
      // arrowBack
      url: '11exch.com/register'
    }),
    methods: {
      changeMode() { 
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      }
    }
  };
  </script>
<style>
.gradient-background {
  position: fixed;
  height: 100%;
  width: 100%;
}
</style>
  