import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '../views/HomeView.vue'
import MatchInfo from '../views/MatchInfo/MatchInfo.vue'
import Squad from '../views/MatchInfo/Squad.vue'

import SeriesView from '../views/SeriesView/SeriesView.vue'

import PrivacyPolicy from "./../pages/PrivacyPolicy.vue";
import TermsCondition from "./../pages/TermsCondition.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/match-info/:match_id?',
    name: 'match-info',
    component: MatchInfo
  },
  {
    path: '/series/:series_id',
    name: 'series-view',
    component: SeriesView
  },
  {
    path: '/squad/:match_id',
    name: 'squad',
    component: Squad
  },
  {
    path: 'privacy-policy',
    name: 'privacy_policy',
    component: PrivacyPolicy
  },
  {
    path: 'terms-and-conditions',
    name: 'terms_and_conditions',
    component: TermsCondition
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
