<template>
  
  <MainLayout :appBarExtensionHeight="bottomTab == 0 ? 34 : 0">

    <template v-slot:appbarExtension>
      <v-tabs v-if="bottomTab == 0" class="main-tabs" v-model="tab">
        <v-tab v-for="(item, i) in tabs" :key="i">{{ item }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:bottomNavigation>
      <v-bottom-navigation v-model="bottomTab" color="primary" app grow style="align-items: center;">
        <v-btn v-for="(item, i) in bottomNavigation" :key="i" :ripple="false" color="transparent">
          <span>{{ item.name }}</span>
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>

    <v-tabs-items :value="bottomTab" :touchless="true">
      <v-tab-item>
        <HomeTab v-model="tab"/>
      </v-tab-item>
      <v-tab-item>
        <SeriesTab/>
      </v-tab-item>
      <!-- <v-tab-item>
        <iframe style="height: 100%; width: 100%;" src="https://syndication.twitter.com/srv/timeline-list/screen-name/ICC/slug/cricketers-on-twitter?dnt=false&embedId=twitter-widget-0&features=e30%3D&frame=false&hideBorder=false&hideFooter=false&hideHeader=false&hideScrollBar=false&lang=en&origin=file%3A%2F%2F%2FUsers%2Faniket%2FProjects%2Ftemp.html&showHeader=true&transparent=false&widgetsVersion=aaf4084522e3a%3A1674595607486"></iframe>
      </v-tab-item> -->
      <v-tab-item>
        <MoreTab/>
      </v-tab-item>
    </v-tabs-items>    

  </MainLayout>
  
</template>

<script>
  
  import MainLayout from "../layouts/MainLayout.vue";

  import HomeTab from "../components/HomeView/HomeTab.vue";
  import SeriesTab from "../components/HomeView/SeriesTab.vue";

  import MoreTab from "../pages/MoreTab.vue";

  import EmptyState from "./../components/EmptyState.vue";

  import { Tweet, Moment, Timeline } from 'vue-tweet-embed'

  export default {
    name: 'Home',
    components: {
      MainLayout,
      HomeTab, SeriesTab, MoreTab,
      EmptyState,
      Timeline
    },
    data() {
      return {
        bottomTab: 0,
        // tab: 0,
        tabs: [
          'Home', 'Live', 'Upcoming', 'Results'
        ],
        bottomNavigation: [
          {
            name: 'Home',
            icon: 'mdi-home'
          },
          {
            name: 'Series',
            icon: 'mdi-cricket'
          },
          // {
          //   name: 'Fixtures',
          //   icon: 'mdi-calendar-month'
          // },
          // {
          //   name: 'News',
          //   icon: 'mdi-newspaper-variant-outline'
          // },
          {
            name: 'More',
            icon: 'mdi-dots-horizontal'
          }
        ],

        news_loading: true

      }
    },
    mounted() {
      
    },
    computed: {
      tab:{
        get() {
          return this.$route.query?.tab ? parseInt(this.$route.query.tab) : 0;
        },
        set(newValue) {
          this.$router.replace({
            query:{
              tab: newValue
            }
          })
        }
      }
    },
    methods:{
      onNewsIframeLoad() {
        console.log("onNewsIframeLoad");
        this.news_loading = true;
      }
    }
  }
</script>
<style lang="scss">
.custom-tabs{
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  color: #ffffff;
  .active{
    color: white;
    position: relative;
    &:before {
      content: "";
      height: 2px;
      width: 100%;
      background-color: white;
      position: absolute;
      bottom: -4px;
    }
  }
}
.matches-chips{
  .v-chip--active
  {
    background: white;
    box-shadow: 1px 1px 3px 1px #869597;
    color: black;
  }
  }


.main-tabs {
  .v-tab.v-tab--active, .v-tabs-slider
   {
      color: #a71f67 !important;
   }
  .v-slide-group__content.v-tabs-bar__content 
  {
    width: 100%;
    justify-content: space-around;
  }
}

</style>