<template>
    <div class="d-flex flex-column align-center" style="opacity: .2;">

        <img :src="cricket_icon" style="height: 140px; margin-top: 80px;">

        <div class="mt-6">
            No data to show right now.
        </div>

    </div>
</template>
<script>
import cricket_icon from "./../assets/cricket.png";
export default {
    name: 'EmptyState',
    data() {
        return {
            cricket_icon
        }
    }
}
</script>